import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import imagen from './imagenes/hombre.png';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			registro :
			{
				id : '',
				tipo : '',
				mensaje : '',
				grado : '',
				grupo : '',
				apellido1 : '',
				apellido2 : '',
				nombre : '',
				hora : '',
				curp : '',
				color : ''
			},
			movimientos : [],
			foto : '',
			conectado : false
		}
		this.registrar_movimiento = this.registrar_movimiento.bind(this);
	}

	componentDidMount()
	{
	
		var { conectado, foto } = this.state;
		let temp = this;

		request.get(`/ProbarConexion`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					conectado = true;
		 			temp.setState({ conectado : conectado });
		 		}
		 	}
		})
		.catch(error => {} );

		foto=imagen;

		this.setState({ conectado : conectado, foto : foto });
	}

	submit=(evt)=>{
		evt.preventDefault();
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;		
		var largo=value.length;
		
		if(largo===10)
		{
			this.registrar_movimiento(value); 
			event.target.value='';
		}
	}

	registrar_movimiento(idTarjeta)
	{
		var { registro, movimientos, foto } = this.state;
		var arreglo = movimientos;
		let temp = this;

		let registro2 =
		{
			id : '',
			tipo : '',
			mensaje : '',
			grado : '',
			grupo : '',
			apellido1 : '',
			apellido2 : '',
			nombre : '',
			hora : '',
			curp : '',
			color : ''
		};

		request.get(`/RegistrarMovimiento/${idTarjeta}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			registro2 = response.data;
		 			if(registro2['color']===1)
		 				registro2['color']="NARANJA";
		 			else
		 				registro2['color']="VERDE";
		 			arreglo = [registro2, ...arreglo];
		 			temp.setState({ registro2 : registro2, movimientos : arreglo });
		 		}
		 		else
		 		{
		 			registro.credencial="";
	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
		 			temp.setState({ registro : registro });
		 		}
		 	}
		})
		.catch(error => {} );

		request_file().post(`/MostrarImagen?idTarjeta=${idTarjeta}`)
		.then(response =>
		{
			const file = new Blob([response.data], {type: 'image/jpeg' });
			const fileURL = URL.createObjectURL(file);
			foto=fileURL;
			temp.setState({ foto : foto });
		})
		.catch(error => { });

		this.setState({ registro : registro, movimientos : movimientos });
	}

	render()
	{
		let { conectado, movimientos, foto } = this.state;

		return (
			<div className="body">
				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-12">
							<Form onSubmit={this.submit}>
								<Row>
									<Col>
										<div className="pl-1 pr-1 input-group mb-1 loginForm">
											<span className="input-group-text" id="basic-addon1"> Registro de entrada </span>
											<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" aria-label="Credencial" aria-describedby="basic-addon1" onChange={this.handleInputChange}/>
											<span className="input-group-text">Esc. Sec. #5 Moises Saenz Garza</span>
										</div>
									</Col>
								</Row>
							</Form>
		{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 input-group mb-1 loginForm">
										<table>
											<tr className="f ml-2">
												<td className="f">
													<img className="cuadroFoto" align="middle" src={foto} class="img-thumbnail" alt="..."/>
												</td>
								

												<td className="datos">
													<span className="ne">CURP:</span>
													{
														movimientos.length>0? 
															<span className="ne b"> {movimientos[0].curp} </span>
														:
															""
													}
													<br/>
													<span className="ne"> APELLIDO(s):</span>
													{
														movimientos.length>0? 
															<span className="ne b"> {movimientos[0].apellido1} </span>
														:
															""
													}
													<br/>
													<span className="ne"> NOMBRE(s): </span>
													{
														movimientos.length>0? 
															<span className="ne b"> {movimientos[0].nombre} </span>
														:
															""
													}
													<br/>
													<span className="ne"> GRUPO: </span>
													{
														movimientos.length>0? 
															<span className="ne b"> {movimientos[0].grado} {movimientos[0].grupo} </span>
														:
															""
													}
													<br/>
													<span className="ne"> COLOR: </span>
													{
														movimientos.length>0? 
															<span className="ne b"> {movimientos[0].color} </span>
														:
															""
													}
												</td>
											</tr>
										</table>
									</div>
								</Col>
							</Row>
								
		{/* LISTA DE REPORTE */}
							<Form>
								<Row>
									<Col>
										<div className="pl-2 pr-2 loginForm maxh lista">
										{
											conectado ?

												<table className="table table-responsive-sm table-striped table-bordered table-sm">
													<tbody>
													{
														movimientos.map((item, i) => 
															<tr>
																<td className="cm cen ne"> { movimientos.length-i } </td>
																{
																	parseInt(item.tipo)===1 ?
																		<td className="cc cen"> <span className="oi ve" data-glyph="arrow-thick-top" aria-hidden="true"></span> </td>
																	:
																		<td className="cc cen"> <span className="oi ro" data-glyph="arrow-thick-bottom" aria-hidden="true"></span> </td>
																}
																{
																	//parseInt(item.mensaje)===0 ?
																		<td className="cc cen"> <span className="oi ro" data-glyph="envelope-open" aria-hidden="true"></span> </td>
																	//:
																	//	<td className="cc cen"> <span className="oi ve" data-glyph="envelope-open" aria-hidden="true"></span> </td>
																}
																<td className="cm cen ne"> {item.grado}{item.grupo} </td>
																<td className="cx ne"> {item.apellido1}, {item.nombre} </td>
																<td className="cg cen ne"> {item.hora} </td>
															</tr>
														)
													}
													</tbody>
												</table>
											:
												<span className="oi ro" data-glyph="signal" aria-hidden="true"> No hay conexión a internet </span>
										}					
										</div>
									</Col>
								</Row>
							</Form>
							
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default App;